











































































































import Vue from 'vue';
import vtable from '@/components/table.vue';
import { RootState } from '@/store';
import { mapActions } from 'vuex';
import VForm from '@/types/vuetify';
import { FileCategoryType, FileType } from '@/types/sqlite';

export default Vue.extend({
	name: 'FileCategoryList',
	data(): {
		selectedFileCategoryId: number | null;
		editing: FileCategoryType | null;
		dialog: boolean;
		dialogFile: boolean;
		valid: boolean;
		validFile: boolean;
		formFileCategoryName: string | null;
		formFile: string | null;
	} {
		return {
			selectedFileCategoryId: null,
			editing: null,
			dialog: false,
			dialogFile: false,
			valid: false,
			validFile: false,
			formFileCategoryName: null,
			formFile: null
		};
	},
	components: {
		vtable
	},
	computed: {
		fileCategory(): FileCategoryType | null {
			return this.selectedFileCategoryId
				? this.fileCategories.data.find(
						(v: FileCategoryType) => v.catid === this.selectedFileCategoryId
				  )
				: null;
		},
		fileCategories() {
			return {
				headers: [
					{ value: 'name', text: 'Name' },
					{ value: 'actions', text: 'Actions' }
				],
				data: this.$store.state.projectCurrent.fileCategories.data.filter(
					(v: FileCategoryType) => v.status === 1
				)
			};
		},
		filesByFileCategory(): any {
			if (!this.selectedFileCategoryId) return [];
			const filesInsideFileCategory = this.$store.getters.getFilesByFileCategory(
				this.selectedFileCategoryId
			);
			return {
				headers: [{ value: 'name', text: 'Name' }],
				data: (this.$store
					.state as RootState).projectCurrent.sources.data.filter(
					(v: FileType) =>
						v.status === 1 && filesInsideFileCategory.includes(v.id)
				)
			};
		},
		filesNotInFileCategory(): any {
			if (!this.selectedFileCategoryId) return [];
			const filesInsideFileCategory = this.$store.getters.getFilesByFileCategory(
				this.selectedFileCategoryId
			);
			return (this.$store.state as RootState).projectCurrent.sources.data
				.filter(
					(v) => v.status === 1 && !filesInsideFileCategory.includes(v.id)
				)
				.map((v) => ({ ...v, text: v.name, value: v.id }));
		}
	},
	methods: {
		...mapActions([
			'addFileCategory',
			'updateFileCategory',
			'deleteFileCategory',
			'addFileCategoryFile',
			'deleteFileCategoryFile',
			'updateMemoFileCategory'
		]),
		selectionChange({
			item,
			value
		}: {
			item: FileCategoryType;
			value: boolean;
		}) {
			this.selectedFileCategoryId = value ? item.catid : null;
		},
		save() {
			if (this.editing) {
				const item = { ...this.editing, name: this.formFileCategoryName };
				this.updateFileCategory(item);
				this.dialog = false;
			} else {
				this.addFileCategory(this.formFileCategoryName);
				this.dialog = false;
			}
		},
		saveFileCategoryFile() {
			if (!this.formFile || !this.selectedFileCategoryId) return;
			this.addFileCategoryFile({
				fid: this.formFile,
				catid: this.selectedFileCategoryId
			});
			this.dialogFile = false;
		},
		openNewFileCategory() {
			this.editing = null;
			if (this.$refs.form) (this.$refs.form as VForm).reset();
			this.dialog = true;
		},
		openEditFileCategory(item: FileCategoryType) {
			this.editing = item;
			this.formFileCategoryName = this.editing!.name;
			this.dialog = true;
		},
		openFileCategoryFile() {
			this.dialogFile = true;
		},
		handleDeleteFileCategoryFile(file: FileType) {
			if (!file || !this.selectedFileCategoryId) return;
			this.deleteFileCategoryFile({
				fid: file.id,
				catid: this.selectedFileCategoryId
			});
		},
		handleDeleteFileCategory(fileCategory: FileCategoryType) {
			if (fileCategory.catid === this.selectedFileCategoryId)
				this.selectedFileCategoryId = null;
			this.deleteFileCategory(fileCategory);
		}
	}
});
